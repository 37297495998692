<template>
  <div :style="CSS" :class="CSSClasses" class="pagination" ref="pagination">
    <div class="pagination-wrapper" v-if="isCircle">
      <i class="el-icon-arrow-left btn btn--prev" @click="prev"></i>
      <div class="pagination-container">
        <div class="slider-line" ref="sliderLine" :style="`transform: translateX(${widthSliderline}px);`">
          <div
            v-for="(pageNumber, index) of circles"
            :key="index"
            class="little-dot"
            :class="{ active: currentPage === pageNumber }"
          >
          </div>
        </div>
      </div>
      <i class="el-icon-arrow-right btn btn--next" @click="next"></i>
    </div>
    <el-pagination
      v-else
      small
      :page-size="limit"
      :layout="layout"
      @current-change="currentChange"
      :total="total"
    >
    </el-pagination>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
export default {
  mixins: [mixin],
  name: 'pagination',
  props: {
    value: {
      frozen: true
    },
    limit: {
      frozen: true
    },
    replicationGuid: {
      frozen: true
    },
    settings: {
      frozen: true
    },
    isTotal: {
      type: Boolean,
      description: 'Показать кол-во записей',
      default: false
    },
    isCircle: {
      type: Boolean,
      description: 'В виде точек',
      default: false
    }
  },
  data () {
    return {
      total: null,
      currentPage: 1,
      widthSliderline: 0
    }
  },
  computed: {
    layout () {
      if (this.settings?.sourceId && this.isTotal) {
        return 'total, prev, pager, next'
      } else {
        return 'prev, pager, next'
      }
    },
    circles () {
      return Math.ceil(this.total / this.limit)
    }
  },
  methods: {
    next () {
      // Если последняя точка
      if (this.currentPage === this.circles) {
        return
      }
      // Если currentPage > 1 и в контейнер pagination НЕ помещаются точки - то sliderLine сдвинуть на 18px
      if (this.currentPage > 1 && this.$refs.pagination.scrollWidth < this.$refs.sliderLine.scrollWidth) {
        this.widthSliderline -= 18
      }
      this.currentPage += 1
      this.currentChange(this.currentPage)
    },
    prev () {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        // Если currentPage НЕ первая страница и в контейнер pagination НЕ помещаются точки - то sliderLine сдвинуть на 18px
        if (this.currentPage !== 1 && this.$refs.pagination.scrollWidth < this.$refs.sliderLine.scrollWidth) {
          this.widthSliderline += 18
        }
      }
      this.currentChange(this.currentPage)
    },
    currentChange (page) {
      page--
      let usersCurrentPage = page * this.limit
      this.$emit('input', {
        name: 'offset',
        replicationGuid: this.$parent.block.pagination?.replicationGuid,
        value: usersCurrentPage
      })
    },
    getLoadParams (settings = {}) {
      const filters = this.getFilters(settings)
      const params = {
        offset: 0,
        id: { func: 'count' },
        limit: settings.limit,
        state_id: settings.stateId
      }

      if (filters.length) {
        params['where'] = { and: [...filters] }
      }

      return params
    },
    getFilters (settings = {}) {
      const filters = []
      const replicationFilters = settings?.filters

      if (replicationFilters) {
        replicationFilters.forEach((filter) => {
          const object = {}
          const equalsType = filter.isXref ? 'equals_any' : 'eq'
          object[equalsType] = {}

          if (!filter.alias) {
            return filters
          }

          const alias = filter.isXref ? `${filter.alias}id` : filter.alias
          // Обработка типов фильтра
          if (!filter.type || filter.type === 'field') {
            if (this.getModel()[filter.attribute]) {
              object[equalsType][alias] = this.getModel()[filter.attribute]
            }
          } else if (filter.type === 'constant') {
            object[equalsType][alias] = filter.attribute
          } else if (filter.type === 'current_user') {
            object[equalsType][alias] =
              this.$store.getters['Authorization/userId']
          }

          if (Object.keys(object[equalsType]).length > 0) {
            filters.push(object)
          }
        })
      }

      return filters
    },
    async getRegistryData (settings) {
      const params = this.getLoadParams(settings)
      let data = await this.$http.post(
        `${this.$config.api}/registryservice/registry/${settings.sourceId}`,
        params,
        {
          hideNotification: true
        }
      )
      return data.data
    }
  },
  async mounted () {
    if (this.settings?.sourceId) {
      const data = await this.getRegistryData(this.settings)
      this.total = data.data[0].count
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  .little-dot {
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 100%;
    display: block;
    margin: 0 6px;
    position: relative;
    z-index: 10;
    flex: none;
    transition: all 0.5s;
    &.active {
      width: 16px;
      height: 16px;
      background-color: #0046cc;
    }
  }
  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pagination-container {
    width: 100%;
    overflow: hidden;
  }
  .slider-line {
    display: flex;
    align-items: center;
    // justify-content: center;
    transition: all 0.5s;
  }
  .btn {
    cursor: pointer;
    width: 40px;
    transition: opacity 0.2s;
    z-index: 10;
  }
  .btn:hover {
    opacity: 0.6;
  }
}
</style>
